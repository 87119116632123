import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';

import styles from '../styles/index.module.scss';
import ucsfLogo from '../../images/ucsf-logo.png';
import harvardCrest from '../../images/harvard-crest.png';
import profileYulin from '../../images/team/yulin.jpg';
import profileGeorge from '../../images/team/george.jpg';
import profileKirsten from '../../images/team/kirsten.jpg';

export default () => {
    const { t } = useTranslation('index');

    return (
        <div id="team">
            <div className={styles.sectionHeader}>
                <h2>{t('team_heading')}</h2>
            </div>

            <Container>
                <Row className={styles.member}>
                    <Col md="auto">
                        <img src={profileYulin} className={styles.memberPhoto} alt="Yulin Hswen, ScD, MPH" />
                    </Col>
                    <Col md={true} className={styles.memberText}>
                        <p>
                            “{t('yulin_bio')}”
                        </p>
                        <div className={styles.memberInfo}>
                            <div className={styles.memberLogos}>
                                <img src={ucsfLogo} alt="UCSF" />
                                <img src={harvardCrest} alt="Harvard University" />
                            </div>
                            <div className={styles.memberCredentials}>
                                <h3>Yulin Hswen, ScD, MPH</h3>
                                <ul>
                                    <li>
                                        Assistant Professor of Epidemiology and Biostatistics<br />
                                        Director of the “Data Hub”, Bakar Computational Health Sciences Institute<br />
                                        University of California, San Francisco
                                    </li>
                                    <li>
                                        Adjunct Faculty, Computational Epidemiology Lab<br />
                                        Harvard Medical School
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row className={styles.member}>
                    <Col md="auto">
                        <img src={profileGeorge} className={styles.memberPhoto} alt="George Rutherford, MD" />
                    </Col>
                    <Col md={true} className={styles.memberText}>
                        <p>
                            “{t('george_bio')}”
                        </p>
                        <div className={styles.memberInfo}>
                            <div className={styles.memberLogos}>
                                <img src={ucsfLogo} alt="UCSF" />
                            </div>
                            <div className={styles.memberCredentials}>
                                <h3>George Rutherford, MD</h3>
                                <ul>
                                    <li>
                                        Professor of Epidemiology,<br />
                                        University of California, San Francisco
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row className={styles.member}>
                    <Col md="auto">
                        <img src={profileKirsten} className={styles.memberPhoto} alt="Kirsten Bibbins-Domingo, PhD, MD, MAS" />
                    </Col>
                    <Col md={true} className={styles.memberText}>
                        <p>
                            “{t('kirsten_bio')}”
                        </p>
                        <div className={styles.memberInfo}>
                            <div className={styles.memberLogos}>
                                <img src={ucsfLogo} alt="UCSF" />
                            </div>
                            <div className={styles.memberCredentials}>
                                <h3>Kirsten Bibbins-Domingo, PhD, MD, MAS</h3>
                                <ul>
                                    <li>
                                        Lee Goldman, MD Endowed Chair in Medicine
                                    </li>
                                    <li>
                                        Professor and Chair of the Department of Epidemiology and Biostatistics,<br />
                                        University of California, San Francisco
                                    </li>
                                    <li>
                                        Inaugural Vice Dean for Population Health and Health Equity,<br />
                                        UCSF School of Medicine
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
