import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import Hero from '../components/home/hero';
import Team from '../components/home/team';
import About from '../components/home/about';
import Layout from "../components/layout";
import SEO from "../components/seo";
import styles from '../components/styles/index.module.scss';

const IndexPage = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <SEO title={t('home')} />
            <main className={styles.main}>
                <Hero />
                <Team />
                <About />
            </main>
        </Layout>
    );
};

export default IndexPage;
