import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

import styles from '../styles/index.module.scss';

export default () => {
    const { t } = useTranslation('index');

    return (
        <div id="about" className="mt-5">
            <Container>
                <section className={styles.section}>
                    <h2>{t('about_heading')} <span className="text-primary font-weight-bold">COVID</span><span className="font-weight-bold">SEEKER</span></h2>

                    <p className="font-weight-normal">
                        {t('about_1')}
                    </p>

                    <p>{t('about_2')}</p>
                    <ul>
                        {t('about_3', { returnObjects: true }).map((item, i) => <li key={i}>{item}</li>)}
                    </ul>

                    <p className="font-weight-normal">{t('about_4')}</p>

                    <p className="font-weight-normal">
                        <Trans t={t} i18nKey="about_5">
                            For help completing the survey, or if you have any questions, please contact <a href="mailto:yulin.hswen@ucsf.edu">yulin.hswen@ucsf.edu</a>.
                        </Trans>
                    </p>

                    <h4 className="mt-4">{t('privacy_heading')}</h4>
                    <p>
                        <Trans t={t} i18nKey="privacy_1">
                            All of the data collected for the Covidseeker research project follows strict practices to ensure your privacy. <a href="https://www.ucsf.edu/website-privacy-policy">Read our complete privacy policy here.</a>
                        </Trans>
                    </p>

                    <p className="font-weight-normal">
                        {t('privacy_2')}
                    </p>
                </section>

                <section className={styles.section}>
                    <h2>{t('research_heading')}</h2>

                    <Row>
                        <Col md={6} className={styles.subsection}>
                            <h3>{t('research_1_heading')}</h3>
                            <p>
                                {t('research_1')}
                            </p>
                        </Col>
                        <Col md={6} className={styles.subsection}>
                            <h3>{t('research_2_heading')}</h3>
                            <p>
                                {t('research_2')}
                            </p>
                        </Col>
                    </Row>
                </section>
            </Container>
        </div>
    );
}
