import React from 'react';
import { Jumbotron, Container, Button } from 'react-bootstrap';
import Carousel from 'nuka-carousel';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';

import styles from '../styles/index.module.scss';
import ucsfLogo from '../../images/ucsf-logo-signature.svg';
import ucsfHealthLogo from '../../images/ucsf-health.svg';
import ucsfEpidemiologyLogo from '../../images/ucsf-epidemiology.jpg';
import ucsfComputationalHealthLogo from '../../images/ucsf-computational-health.png';
import jumbo1 from '../../images/jumbo-1.jpg';
import jumbo2 from '../../images/jumbo-2.jpg';
import jumbo3 from '../../images/jumbo-3.jpg';

export default () => {
    const { t } = useTranslation('index');

    return (
        <div id="top">
            <Jumbotron fluid className={styles.jumbotron}>
                <div className={styles.jumboCarouselWrapper}>
                    <Carousel 
                        className={styles.jumboCarousel} 
                        autoplay={true}
                        withoutControls={true}
                        wrapAround={true}
                        autoplayInterval={6000}
                        speed={1000}
                    >
                        <img className={styles.jumboCarouselImage} src={jumbo1} />
                        <img className={styles.jumboCarouselImage} src={jumbo2} />
                        <img className={styles.jumboCarouselImage} src={jumbo3} />
                    </Carousel>
                </div>
                
                <div className={styles.jumboText}>
                    <Container>
                        <div>
                            <img src={ucsfLogo} className={styles.ucsfLogo} alt="University of California, San Francisco" />
                        </div>
                        
                        <h1>
                            {t('hero_heading')}
                        </h1>
                        <p className="mt-3">{t('hero_choose_to_participate')}</p>
                        <ul>
                            {t('hero_list', { returnObjects: true }).map((item, i) => <li key={i}>{item}</li>)}
                        </ul>
                        <p>
                            {t('hero_contributions')}
                        </p>

                        <div className={styles.jumboChaser}>
                            <Link to="/upload" className="btn btn-primary btn-lg mb-4">{t('hero_cta')}</Link>

                            <p>
                                {t('hero_disclaimer')}
                            </p>
                        </div>
                    </Container>
                </div>
            </Jumbotron>

            <Container>
                <div className={styles.logoContainer}>
                    <img src={ucsfHealthLogo} style={{ height: '49px' }} alt="UCSF Health" />
                    <img src={ucsfEpidemiologyLogo} style={{ height: '76px' }} alt="UCSF Department of Epidemiology &amp; Biostatistics" />
                    <img src={ucsfComputationalHealthLogo} style={{ height: '55px' }} alt="UCSF Bakar Computational Health Sciences Institute" />
                </div>
            </Container>
        </div>
    );
}
